export const cookiePage: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Retina Spain",
  footer: "Footer Consumer - Retina Spain",
  exitPopup: "Exit Popup - DTC - Retina Spain",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  topNav: "Top Navigational Menu Consumer - Retina Spain",
  siteLogo: "Site logos - Retina Spain",
  cookieContent: "Cookie policy - DTC - Retina Spain",
  banner: "Banner - HTML - Cookie Page - DTC - Retina Spain",
  footerClassName: "common-footer"
}
