import styled from 'styled-components';
export const CookiePolicy = styled.div`
  .cookie-policy {
    .privacypolicycontent {
      padding-top: 80px;
      padding-bottom: 110px;

      .ot-sdk-show-settings {
        border: 1px solid #000 !important;

        &:before {
          top: 50% !important;
          transform: translateY(-50%) !important;
        }
      }
      #ot-sdk-cookie-policy {
        #ot-sdk-cookie-policy-v2 {
          &.ot-sdk-cookie-policy {
            font-size: inherit;
            line-height: inherit;

            #cookie-policy-title {
              color: rgb(0, 0, 0);
              font-family: 'Gotham', Arial, Helvetica, sans-serif ;
            }
          }
        }

        .ot-sdk-cookie-policy {
          h3 {
            &.ot-sdk-cookie-policy-title {
              font-size: 2.7rem;
              line-height: 1.41;

            }
          }
          #cookie-policy-description,
          .ot-sdk-cookie-policy-group-desc,
          table {
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 3.2rem;
            font-family: 'Gotham-Book', Arial, Helvetica, sans-serif ;
            color: rgb(0, 0, 0);
            th,td {
              color: rgb(0, 0, 0);
            }
            tr {
              td {
                @media screen and (max-width: 767px) {
                  word-wrap: break-word;
                }

              }
            }
          }

          .ot-sdk-cookie-policy-group {
            font-size: 1.2em;
            line-height: 1.3;
            color: rgb(0, 0, 0);
            font-family: 'Gotham', Arial, Helvetica, sans-serif ;
          }
        }
      }

      p,
      li {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.2rem;
      }
    }
  }
`;